import React, { useEffect, useState } from "react";
import newsData from "./news.json"; // Adjust the path as necessary
import "./News.css";

// Utility function to limit text to a certain number of words
const limitText = (text, wordLimit) => {
  if (!text) return ""; // Prevents error if text is null or undefined
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

const News = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout); // Clean up the timeout
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading News...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="error-container"
        style={{ backgroundImage: "url('/path-to-your-image/error-background.jpg')" }} // Ensure the correct image path
      >
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="News">
      <h1 className="Newstitle">News</h1>
      <div className="Newsbox">
        {newsData.length > 0 ? (
          newsData.map((newsItem) => (
            <div key={newsItem.id} className="newscontainer">
              <div className="newsconimage">
                {newsItem.img ? (
                  <img src={newsItem.img} alt="" className="newsimage" />
                ) : (
                  <div className="placeholder-image">No Image Available</div>
                )}
              </div>
              <div className="newtextcon">
                <h1 className="newtextlist">{limitText(newsItem.title, 10)}</h1>
                <ul className="newstablelist">
                  <li>{limitText(newsItem.disp1, 50)}</li>
                </ul>
                <a
                  href={newsItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Read More</button>
                </a>
              </div>
            </div>
          ))
        ) : (
          <div>No News Available</div> // Handling the case where no news is returned
        )}
      </div>
    </div>
  );
};

export default News;

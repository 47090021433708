import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Review.css";
import image from "./360_F_326985142_1aaKcEjMQW6ULp6oI9MYuv8lN9f8sFmj.jpg";

const Review = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="slider-container">
      <h2 className="reviewtitle">
        Student <b>Review</b>
      </h2>
      <Slider {...settings}>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
        
          </div>
          <div className="reviewtext">
          <h1>Vishwanath Shastry</h1>
          <h2>Soc Design Engineer at Intel</h2>
          <p>EpitomeCircuits provides outstanding VLSI Design/Layout training at an industry level, utilizing industry-standard tools to equip students with the skills needed to excel in the VLSI industry. They offer excellent placement assistance and boast alumni in some of the most well-known VLSI companies.
          “ I am personally grateful to EpitomeCircuits for empowering me with the knowledge and experience essential for success in my role as a SOC Design Engineer at Intel."</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Chirag B V</h1>
          <h2>Analog Circuit Designer Synopsys</h2>
          <p>In my fifth semester of engineering, I joined Epitome Circuits, where I saw the dedication of both seniors and peers. With expert mentorship, we received curated study materials, hands-on labs, and personalized feedback through mock interviews, helping us improve skills and navigate challenges effectively. It has been an invaluable experience.</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Sneha M</h1>
          <h2>A&MS Layout Design Engineer at Synopsys</h2>
          <p>EpitomeCircuits stands out as an excellent avenue for individuals with aspirations of venturing into the VLSI industry. This platform not only lets you stay updated on what's happening in the industry but also helps you learn both the theory and hands-on skills you need.
          During my final year, I joined VLSI Layout training at EpitomeCircuits, guided by experienced industry professionals. The training deepened my understanding of complex layouts like PLL, BGR, Op-amp, ADC, and SRAM. I'm grateful to my mentors for guiding me toward a career I've always aspired to pursue.</p>
          
          </div>
        </div>
        <div className="reviewcon">
          <div className="reviewimage">
            <img src={image} alt="Slide 1" className="slide-imagea" />
            <div className="star">⭐⭐⭐⭐⭐</div>
          </div>
          <div  className="reviewtext">
          <h1>Apeksha GD</h1>
          <h2>Layout Engineer at Texas Instruments</h2>
          <p>I am happy to express my sincere gratitude to EpitomeCircuits for their invaluable guidance and support, which played a pivotal role in shaping my career journey.
          “ After completing my bachelor's, I joined EpitomeCircuits, where intensive training and mentorship equipped me with technical skills and industry insights. This rigorous program was crucial in helping me navigate the job market. I am delighted to share that, thanks to EpitomeCircuits' excellent training, I secured a layout engineer position at Texas Instruments, marking a significant career achievement.</p>
          
          </div>
        </div>
        <div className="reviewcon">
        <div className="reviewimage">
          <img src={image} alt="Slide 1" className="slide-imagea" />
          <div className="star">⭐⭐⭐⭐⭐</div>
          
        </div>
        <div  className="reviewtext">
        <h1>Varshitha S V</h1>
        <h2>Mircon Technology</h2>
        <p>pitome Circuits have been helping students to pursue a flourishing career in the VLSI industry. I definitely would recommend this course to the students who are passionate about learning Layout and Circuit design. Young minds of engineering require a bridge between the college and the industry and Epitome circuits have been doing a wonderful job in bridging that gap. I am truly grateful for the opportunity provided by my mentors and hope to perform my tasks well at my company. 
        The concepts they teach are well planned and executed.</p>
        
        </div>
      </div>
      <div className="reviewcon">
      <div className="reviewimage">
        <img src={image} alt="Slide 1" className="slide-imagea" />
        <div className="star">⭐⭐⭐⭐⭐</div>
        
      </div>
      <div  className="reviewtext">
      <h1>Ritika Patil</h1>
      <h2>Custom Layout Design Engineer at FerminoIC Design</h2>
      <p>I am absolutely delighted to share my experience with EpitomeCircuits and the exceptional training they provided, which enabled me to secure a position at Fermionic Design. The hands-on learning, expert guidance, and real-world projects at EpitomeCircuits equipped me with the skills and knowledge necessary to excel in my new role in Layout Design. I am truly grateful for the opportunities they've opened up for me, and I extend my heartfelt thanks to all the mentors for their invaluable guidance throughout this journey.</p>
      
      </div>
    </div>
    <div className="reviewcon">
      <div className="reviewimage">
        <img src={image} alt="Slide 1" className="slide-imagea" />
        <div className="star">⭐⭐⭐⭐⭐</div>
        
      </div>
      <div  className="reviewtext">
      <h1>Achyuth G Prasad</h1>
      <h2>Layout Design Engineer At Synopsys</h2>
      <p>
      Epitome Circuits offers top-tier VLSI training with a hands-on curriculum bridging academia and industry. Students gain real-world experience with industry-relevant tools and projects, along with placement assistance, ensuring they are well-prepared for rewarding careers in the semiconductor industry.</p>
      
      </div>
    </div>
      
      </Slider>
    </div>
  );
};

export default Review;

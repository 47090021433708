import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from './logo1.png'
import './Header.css'

const Header = () => {
  const navLinkStyle = ({ isActive }) => {
    return {
      textDecoration: isActive ? 'bold' : 'none',
    }
  }

  const [isMobile, setIsMobile] = useState(false);

  return (
    <nav className='navbar'>
      <h3 className='logo'>
        <img src={logo} alt="logo" />
      </h3>
      <ul className={isMobile ? "nav-links-mobile" : "nav-link"} onClick={() => setIsMobile(false)}>
        <li><NavLink style={navLinkStyle} to="/" className="links">Home</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/course" className="links">Courses</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/about" className="links">About</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/project" className="links">Projects</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/placement" className="links">Placement</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/news" className="links">News</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/gallery" className="links">Gallery</NavLink></li>
        <li><NavLink style={navLinkStyle} to="/contact" className="links">Contact</NavLink></li>
        <Link to="/registration" className='links'><button className="demo-button-class">Register</button></Link>
      </ul>
      <button className='mobile-menu-icon' onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <i className='fas fa-times'></i> : <i className='fas fa-bars'></i>}
      </button>
    </nav>
  )
}

export default Header

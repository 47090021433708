import React from "react";
import "./coursespage.css";
import Courses from "./Coursees.json";
import courses from './fa23979609fb00578f3b213c8271f1a4.jpg'

const Coursespage = () => {
  return (
    
        <div className="courses">
          <h1 className="coursestitle">Courses </h1>
          <div className="coursesabputitle1">
         
          <h1 className="coursesuppertitle">OUR COURSES</h1>
          <p className="coursesupper1">
          FOUR MONTHS PER MODULE, ONLINE, MENTORS FROM TOP VLSI COMPANIES</p>
<p className="coursesupper2">
• Mentors with over a Decade of Semiconductor Expertise in Analog ASICs and Memory Design. </p>
<p className="coursesupper2">• Designed and delivered by the Semiconductor Experts.</p>
<p className="coursesupper2">
• Direct access to the mentorship/counselling by VLSI professionals in Intel, Qualcomm, IBM, Synopsys, ARM. </p>
<p className="coursesupper2">• The syllabus is designed to holistically cover all the possible domains in Layout design and maximize the placement opportunities for students.</p>

       </div>
       <div className="upperprojectsection">
      
      <div className="upperimage"><img src={courses} alt="" /></div>
      <div className="upperlink">
      <h1 className="uppertitle">All Courses</h1>
      {Courses.map((courses, i) => ( 
        <div className="upperlinks">
        <a href={`#${courses.id}`}>• {courses.title}</a> </div>
      
      ))}
      </div>
      </div>
          <div className="coursesbox">
            {Courses.map((Courses, i) => (
              <section id={Courses.id}>
              <div key={i} className="coursescontainer">
              <div className="coursesimage"><img src={Courses.img} alt="" className='aboutimage' /></div>
              <div className="coursestextcon">
                <h1 className="coursestitlelist">{Courses.title}</h1>
                <ul className="coursestable">
                <li key={i} className="courseslist">
                {Courses.Discription.d1}
              </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d2}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d3}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d4}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d5}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d6}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d7}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d8}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d9}
                  </li>
                  <li key={i} className="courseslist">
                    {Courses.Discription.d10}
                  </li>
                </ul>
              </div>
              </div>
              </section>
            ))}
          </div>
        </div>
    
  );
};

export default Coursespage;

import React from 'react';
import "./contact.css";
import faq from "./faq.json";


const Contactpart = () => {
 



  return (
    <div className="faq">
      <div className="faq1">
        <div className="section1">
          <h1 className="title">Frequently Asked Questions</h1>
          <div className="accordion1">
            {faq.map((faqItem, i) => (
              <div key={i} className='accordion1234'>
                <ul className="accordion">
                  <li className="slider">
                    <input type="radio" name="accordion" id={`accordion-${i}`} />
                    <label htmlFor={`accordion-${i}`}>{faqItem.que}</label>
                    <div className="content">
                      <p>{faqItem.ans}</p>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="section2">
          <div className='section2info'>
            <div className='contantinfo2'>
             <img src="https://images.vexels.com/content/214784/preview/internet-email-stroke-icon-f26700.png" alt="" />
            </div>
            <div className='textinfo'>
              <div className='address'>
              EpitomeCircuits: online institute offering training, mentorship, and curated study materials.
              </div>
              <div>Phone Number: <a href="tel:+919513800500"> +91-9513800500</a></div>
              <div>Email: <a href="mailto: admin@epitomecircuits.com"> admin@epitomecircuits.com</a>.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactpart;

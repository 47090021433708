import React from 'react'
import './registration.css'

const Registers = () => {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "8d7f693e-0457-4d61-8afb-932f26e9d1ac");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
            alert("Form Submitted Successfully");
        } else {
            console.log("Error", data);
            setResult(data.message);
            alert('Error');
        }
    };
  return (
    <div className='registrer'>
    <div className="registertitle">Registration Form</div>
      <form id='form' onSubmit={onSubmit}>
      
      <h2>Personal Information</h2>
      <div className='personalinfo'>
      <div className='section1 outersection'>
      <input type="text" className="section" id="firstName" name="firstName" placeholder='First Name' required />
      <input type="text" className="section" id="lastName" name="lastName" placeholder='Last Name' required />
      </div>
      <div className="section2 outersection">
      <input type="tel" className="section" id="phoneNumber" name="phoneNumber" placeholder='Phone Number' required />
      <input type="email" className="section" id="email" name="email" placeholder='E-mail' required />
      </div>
      <div className="section3 outersection">
      <textarea id="message" className="section" name="message" rows="4" cols="50" placeholder='Message'></textarea>
      </div>
      </div>
      <div className="educationalinfo">
      
        <h2>Educational Background</h2>
        <div className='education'>
        <input type="number" className="section" id="tenthPercentage" name="tenthPercentage" placeholder='10th Percentage' required />
        <input type="text" className="section" id="tenthSchool" name="tenthSchool" required placeholder='10th School Name' />
        <input type="number" className="section" id="tenthYear" name="tenthYear" required placeholder='10th Passout Year' />
        
        
        
        <input type="number" className="section" id="twelvethDiplomaPercentage" name="twelvethDiplomaPercentage" placeholder='12th Percentage' required />
        <input type="text" className="section" id="twelvethDiplomaCollege" name="twelvethDiplomaCollege" placeholder='12th School Name' required />
        <input type="number" className="section" id="twelvethDiplomaYear" name="twelvethDiplomaYear" placeholder='12th Passout Year' required />
        
        
        <input type="text" className="section" id="beCollege" name="beCollege" placeholder='BE College Name' />
        <input type="number" className="section" id="bePercentage" name="bePercentage" placeholder='BE Percentage'/>
        <input type="number" className="section" id="beYear" name="beYear" placeholder='BE Passout Year' />
       
        
        
        <input type="text" className="section" id="mTechCollege" name="mTechCollege" placeholder='M.Tech College Name' />
        <input type="number" className="section" id="mTechPercentage" name="mTechPercentage" placeholder='M.Tech Percentage'/>
        <input type="number" className="section" id="mTechYear" name="mTechYear" placeholder='M.Tech Passout Year' />
        
      </div>
      </div>
      <button type="submit" className='registerbutton'>Submit</button>
      </form>
    </div>
  )
}

export default Registers

